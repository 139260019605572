import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { RootStyles, ContainerStyles, ModalStyles, TypographyStyles } from 'styles/global/_module';
import { Header } from 'components/includes/_module';
import { Home, Listing, Listings, NewDevelopment } from 'components/pages/_module';
import ContextProvider from './provider/ContextProvider';

function App() {
  return (
    <React.Fragment>
      <RootStyles />
      <TypographyStyles />
      <ContainerStyles />
      <ModalStyles />
      <ContextProvider>
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/listings/:id" component={Listing} />
            <Route path="/listings" component={Listings} />
            <Route path="/listings-hamptons/:id" component={Listing} />
            <Route path="/listings-hamptons" component={Listings} />
            <Route path="/listings-caribbean/:id" component={Listing} />
            <Route path="/listings-caribbean" component={Listings} />
            <Route path="/new-development" component={NewDevelopment} />
          </Switch>
        </Router>
      </ContextProvider>
    </React.Fragment>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { isTownhouse, numberWithCommas, parseBathrooms, sanitizePhone, parsePhone, parseSeasonlPrice } from 'helpers/listings';
import { mediaMax, mediaMin } from 'styles/mediaQueries';

const ListingCardContainer = styled.div`
  border: 1px solid black;
  margin: 20px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
    flex-direction: row;
  `}
`;

const ListingImageColumn = styled.div`
  ${mediaMin.tabletLandscape`
    width: 65%;
  `}
`;

const ListingImage = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${props => `url("${props.imgSrc}")`};
  ${mediaMax.tabletLandscape`
    height: 40vh;
  `}
`;

const ListingInfoColumn = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
    width: 35%;
  `}
`;

const ListingInfo = styled.div`
  .listing-titles {
    font-size: 16px;
    margin-bottom: 20px;
    ${mediaMin.tabletLandscape`
      font-size: 14px;
    `}
    ${mediaMin.desktop`
      font-size: 16px;
    `}
    ${mediaMin.desktopLarge`
      font-size: 20px;
    `}
    .listing-title {
      margin: 0;
      font-weight: 400;
    }
    .listing-subtitle {
      font-weight: 500;
      margin: 10px 0;
    }
  }
`;

const ContactInfo = styled.div`
  ${mediaMin.tabletLandscape`
    background: rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
  `}
`;

const SmallSection = styled.div`
  margin-bottom: 15px;
  p,
  a {
    font-size: 14px;
    margin: 5px 0;
    ${mediaMin.tabletLandscape`
      font-size: 12px;
    `}
    ${mediaMin.desktop`
      font-size: 14px;
    `}
    ${mediaMin.desktopLarge`
      font-size: 16px;
    `}
  }

  .price {
    font-weight: bold;
    font-size: 14px;
    ${mediaMin.desktopLarge`
      font-size: 20px;
    `}
  }

  .status {
    text-transform: uppercase;
    border: 1px solid black;
    display: inline-block;
    padding: 5px 20px;
    margin: 0;
  }

  a {
    display: block;
  }
`;

const DetailButton = styled.button`
  background: black;
  color: white;
  text-transform: uppercase;
  padding: 20px 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  width: 100%;
  cursor: pointer;
  ${mediaMin.tabletLandscape`
    margin: 15px 0;
  `}
  ${mediaMin.desktopLarge`
    font-size: 16px;
  `}
`;

const ListingCard = ({ rentalBool, listing, baseRoute }) => {
  const {
    id,
    agents,
    building_details,
    listing_details: { current_price, status, seasonal_rent_price },
    location: { place, address },
    media: { original_image_list },
    unit_details: { full_baths, half_baths, maint_cc, beds, property_type, real_estate_tax, unit_number },
    sale_rent
  } = listing;

  let numBathrooms = parseBathrooms(full_baths, half_baths);
  let maintenanceCost = numberWithCommas(maint_cc);
  let phoneNumber = sanitizePhone(agents[0].phone);

  return (
    <ListingCardContainer>
      <span></span>
      <ListingImageColumn>
        <Link to={`${baseRoute}/${id}`}>
          <ListingImage imgSrc={original_image_list[0]} title={`Showcase of ${place}`} />
        </Link>
      </ListingImageColumn>
      <ListingInfoColumn>
        <ListingInfo>
          <div className="listing-titles">
            <h2 className="listing-title">{address}</h2>
            <h4 className="listing-subtitle">{(building_details || { building_name: '' }).building_name}</h4>
            {unit_number && <h4>{unit_number}</h4>}
          </div>
          <SmallSection>
            <p>{listing.location.place}</p>
            <p>{`Listing ID: ${id}`}</p>
          </SmallSection>
          <SmallSection>
            {property_type !== 'Land' && <p>{`${beds} ${beds === 1 ? 'Bedroom' : 'Bedrooms'} ${property_type}`}</p>}
            {property_type !== 'Land' && <p>{`${numBathrooms} ${numBathrooms > 1 ? 'Bathrooms' : 'Bathroom'}`}</p>}
            <p>{property_type}</p>
          </SmallSection>
          <SmallSection>
            <p className="price">{
              sale_rent && sale_rent === 'R'
                ? seasonal_rent_price && seasonal_rent_price.length
                  ? parseSeasonlPrice(seasonal_rent_price).reduce(((result, item) => result ? <>{result}<br />{item}</> : <>{item}</>))
                  : 'Please inquire'
                : current_price ? `$${numberWithCommas(current_price)}` : 'PRICE UPON REQUEST'
            }</p>
            {property_type !== 'Land' && !rentalBool && maint_cc > 0 ? (
              <p>{`${property_type === 'Co-op' ? 'Maintenance:' : 'CC:'} $${maintenanceCost}`}</p>
            ) : null}
            {property_type !== 'Land' && real_estate_tax > 0 && (
              <p>{`${isTownhouse(property_type) ? 'Monthly' : ''} RE: $${numberWithCommas(real_estate_tax)}`}</p>
            )}
          </SmallSection>
          <SmallSection>
            <p>STATUS:</p>
            <p className="status">{status}</p>
          </SmallSection>
        </ListingInfo>
        <ContactInfo>
          <SmallSection>
            <p>CONTACT:</p>
            <p className="name">{`${agents[0].first_name} ${agents[0].last_name}`}</p>
            <a href={`tel:${parsePhone(phoneNumber)}`}>{parsePhone(phoneNumber)}</a>
            <a className="email" href={`mailto:${agents[0].email}`}>
              EMAIL ME
            </a>
          </SmallSection>
        </ContactInfo>
        <Link to={`${baseRoute}/${id}`}>
          <DetailButton>View Details</DetailButton>
        </Link>
      </ListingInfoColumn>
    </ListingCardContainer>
  );
};

export default ListingCard;

/* eslint-disable array-callback-return */
const SFtoSM = sf => {
  const sm = sf / 10.764;
  return sm.toFixed(2);
};

const parseOrientation = orientation => {
  let orientationArray = orientation.split(',');
  orientationArray = orientationArray.map(direction => {
    if (direction === 'N') {
      return 'NORTH';
    }
    if (direction === 'S') {
      return 'SOUTH';
    }
    if (direction === 'E') {
      return 'EAST';
    }
    if (direction === 'W') {
      return 'WEST';
    }
  });
  return orientationArray.join(', ');
};

const numberWithCommas = num => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const parseBathrooms = (full, half) => {
  return full + half * 0.5;
};

const sanitizePhone = num => {
  return num.replace(/[^\d]/g, '');
};

const parsePhone = num => {
  const sanitized = `${sanitizePhone(num)}`;
  return sanitized.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
};

const parsePricePerSQFT = (price, sqft, listingType) => {
  if (listingType === 'R') {
    return `$${numberWithCommas(((price / sqft) * 12).toFixed(2))}`;
  } else {
    return `$${numberWithCommas((price / sqft).toFixed(2))}`;
  }
};

const parseSeasonlPrice = (prices) => {
  return (prices || [])
    .map(season => `$${numberWithCommas(season.price)} for ${season.period} `)
}

const numsToWords = {
  0: 'Studio',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
  11: 'Eleven',
  12: 'Twelve',
  13: 'Thirteen',
  14: 'Fourteen'
};

const isTownhouse = listingType => listingType === 'Single-Family Townhouse';

export {
  isTownhouse,
  SFtoSM,
  parseOrientation,
  numberWithCommas,
  parseBathrooms,
  sanitizePhone,
  parsePhone,
  parsePricePerSQFT,
  parseSeasonlPrice
};

const introCarousel = [
  {
    src: '/images/pages/home/intro_carousel/central_park',
    quote: true,
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/3-east-95th-street',
    caption: '3 East 95th Street, Duplex',
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/12-east-82nd-street',
    caption: '12 East 82nd Street',
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/1730-meadow-lane-southampton',
    caption: '1730 Meadow Lane, Southampton',
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/8-east-62nd-street',
    caption: '8 East 62nd Street',
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/48-forest-road-sag-harbor',
    caption: '48 Forest Road, Sag Harbor',
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/151-wooster-street',
    caption: '151 Wooster Street, #4/5B',
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/220-central-park-south',
    caption: '220 Central Park South, #37B',
    overlay: true
  },
  {
    src: '/images/pages/home/intro_carousel/190-riverside-drive',
    caption: '190 Riverside Drive, The Penthouse',
    overlay: true
  }
];

export default introCarousel;
